import axios from 'axios';

import {baseUrl} from '@/js/util.js'


// 获取施工单位下人员列表
export const RoleList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/GetOrganizationalUserList`,
        params:datas
    })
}

// 删除人员
export const DeleteRole=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/DeleteOrganizationalUser`,
        params:datas
    })
}


// 冻结或解冻
export const UpdateIsFrozen=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/UpdateIsFrozen`,
        data:datas
    })
}


// 审核通过
export const UpdateIsAuthorization=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/UpdateIsAuthorization`,
        data:datas
    })
}
