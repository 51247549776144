<template>
  <div id='ulw'>
    <div class="Implement-add">
      <div class="adds"
           @click="addfn(1)">返回施工单位列表</div>
    </div>

    <div class="Implement-wrap">
      <div class="Implement-title">
        <span class="title-01">序号</span>
        <span class="title-02">姓名</span>
        <span class="title-03">登陆账号</span>
        <span class="title-04">注册时间</span>
        <span class="title-05">状态</span>
        <span class="title-06">操作</span>
      </div>

      <div class="Implement-list"
           v-for='(item,index) in listdata'
           :key="index">
        <span class="title-01 textOverflowHide">{{index+1}}</span>
        <span class="title-02 textOverflowHide">{{item.RealName}}</span>
        <span class="title-03 textOverflowHide">{{item.UserName}}</span>
        <span class="title-04 textOverflowHide">{{item.CreateTime}}</span>
        <span class="title-05 textOverflowHide">{{item.IsAuthorization?'已审核':'未审核'}}</span>
        <span class="title-06 textOverflowHide">
          <i @click="addfn(3,item)">{{item.IsAuthorization?'已审核':'审核'}}</i>
          <i @click="addfn(2,item)" :class = "{colorgreen:item.IsFrozen}">{{item.IsFrozen?"冻结":"解冻"}}</i>
          <i @click="delfn(item)">删除</i>
        </span>
      </div>
    </div>
    <div class="pages"
         v-if="total && total > pageSizes">
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="pageSizes"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>

  </div>

</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage,ElMessageBox } from "element-plus";
import {RoleList,
DeleteRole,UpdateIsFrozen,UpdateIsAuthorization} from '@/js/cscuser';
import { getStorage,setStorage } from "@/js/common";
export default {
  name: '',
  props: {

  },

  setup (props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      total: 0,
      currentPage: 1,
      pageSizes: 10,
      addfalse: false,
      addtitle: '',
      listdata:[],
      editdata:'',
      lineID:''
    });
    const cdata = getStorage("bscdata");


    const setnav = (a, b) => {
      let cachedata = getStorage("cachedata") || {};
      cachedata.SetactiveNavChild = a;
      cachedata.SetactiveNavParent = b;
      setStorage("cachedata", cachedata);
      store.commit("SetactiveNavChild", {
        text: a.title,
        path: a.path,
      });
      store.commit("SetactiveNavParent", {
        text: b.title,
        path: "",
      });
    };
    const methods = {
      // 添加弹框
      addfn: (id,item) => {
        if(id==1){ // 返回上个页面
          setnav({title:'施工单位列表',path:'/home/Construction/list'},{title:'施工单位管理',path:''})
          router.go(-1)
        }else if(id==2){// 冻结解冻
          console.log(item);
          let datas = {
            id:item.ID,
            isFrozen:!item.IsFrozen
          }
          UpdateIsFrozen(datas).then((res) => {
            // 
            console.log(res,'list');
            if (res.data.Code == 1) {
             ElMessage({
                showClose: true,
                message: res.data.Message,
                type: "success",
              });
              methods.RoleList()
            } else {
              ElMessage({
                showClose: true,
                message: res.data.Message,
                type: "error",
              });
            }
          });
        }else if(id==3){// 审核通过
          console.log(item);
          if(!item.IsAuthorization){
            let datas = {
              id:item.ID,
              isAuthorization:true
            }
            UpdateIsAuthorization(datas).then((res) => {
              // 
              console.log(res,'list');
              if (res.data.Code == 1) {
               ElMessage({
                  showClose: true,
                  message: res.data.Message,
                  type: "success",
                });
                methods.RoleList()
              } else {
                ElMessage({
                  showClose: true,
                  message: res.data.Message,
                  type: "error",
                });
              }
            });

          }
        }
      },
      //添加弹框 关闭弹框
      addfalsefn () {
        state.addfalse = false;
      },
      // 列表获取分页数据
      handleCurrentChange: (val) => {
        state.currentPage = val;

      },

    // 获取施工单位列表
      RoleList:()=>{
        let datas={
          ID:state.lineID,
          pageIndex:state.currentPage,
          pageSize:state.pageSizes,
        }
        RoleList(datas).then((res) => {
          // 
          console.log(res,'list');
          if (res.data.Code == 1) {
            state.listdata=res.data.Data.data;
            state.total=res.data.Data.total.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });

      },
      // 删除
      delfn(item){
        ElMessageBox.confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let datas={
            ID:item.ID,
          }
          DeleteRole(datas).then((res) => {
            // 删除施工单位
            console.log(res,'list');
            if (res.data.Code == 1) {
             ElMessage({
                showClose: true,
                message: res.data.Message,
                type: "success",
              });
              methods.RoleList()
            } else {
              ElMessage({
                showClose: true,
                message: res.data.Message,
                type: "error",
              });
            }
          });
        }).catch(() => {
          return false
        });
      },
    };


    onMounted(() => {
      state.lineID = route.query.id;
      methods.RoleList()
    });

    return {
      ...methods,
      ...toRefs(state)
    };

  },

  components: {
    
  }


}

</script>

<style lang='scss' scoped>
#ulw {
  margin: 0 30px;
  box-sizing: border-box;
  .Implement-add {
    height: 76px;
    display: flex;
    align-items: center;
    // justify-content: flex-end;

    box-sizing: border-box;
    .adds {
      width: 137px;
      height: 36px;
      background: #0083ff;
      border-radius: 5px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .Implement-title,
  .Implement-list {
    width: 100%;
    height: 40px;
    background: #e8f3f8;
    border: 1px solid #d1e2e5;
    font-size: 12px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #999999;
    display: inline-flex;
    align-items: center;
  }
  .Implement-list {
    background: transparent;
    span {
      i {
        cursor: pointer;
        &:nth-child(1) {
          color: #0083ff;
        }

        &:nth-child(2) {
          color: #e7111b;
          &.colorgreen{
            color:green;
          }
        }
        &:nth-child(3) {
          color: #e7111b;
        }
      }
    }
    .title-06 {
      display: flex;
      padding: 0;
      justify-content: space-around;
    }
  }
  .Implement-list {
    border-top: none;
  }
  .Implement-title > span,
  .Implement-list > span {
    display: inline-block;
    line-height: 40px;
    padding-left: 1%;
    border-right: 1px solid #d1e2e5;
    box-sizing: border-box;
  }
  .title-01 {
    width: 4%;
  }
  .title-02 {
    width: 20%;
  }
  .title-03 {
    width: 20%;
  }
  .title-04 {
    width: 20%;
  }
  .title-05 {
    width: 20%;
  }
  .title-06 {
    width: 16%;
    text-align: CENTER;
  }

  .pages {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
  }
}
</style>